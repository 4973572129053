<template>
  <div class="bill">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="load"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Bonus.title')" label="Earnings"></title-name>
    </div>

    <div class="total">
      <div class="total-title">{{ $t('Bonus.jinri') }}</div>
      <div class="total-row">
        <div class="total-item" v-for="(item, index) in shouyi.today" :key="index">{{ item.value }} {{ item.name }}</div>
      </div>
      <div class="total-title q-mt-md">{{ $t('Bonus.leiji') }}</div>
      <div class="total-row" >
        <div class="total-item item-border" v-for="(item, index) in shouyi.total" :key="index">{{ item.value }} {{ item.name }}</div>
      </div>
    </div>

    <div class="list">
      <div class="list-name">
        <div>
          {{ $t('Bonus.date') }}
        </div>
        <div class="justify-center">
          {{ $t('Bonus.type') }}
        </div>
        <div class="justify-end">
          {{ $t('Bonus.jine') }}
        </div>
      </div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Bonus.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list-val" v-for="item in list" :key="item.id">
          <div>{{ item.sdate }}</div>
          <div class="text-center">{{ item.lxName }}</div>
          <div class="text-right" v-if="item.cid == 1">{{ item.jine }}{{cnameList[0].cname}}</div>
          <div class="text-right" v-if="item.cid == 2">{{ item.jine }}{{cnameList[1].cname}}</div>
          <div class="text-right" v-if="item.cid == 3">{{ item.jine }}{{cnameList[2].cname}}</div>
          <div class="text-right" v-if="item.cid == 4">{{ item.jine }}{{cnameList[3].cname}}</div>
          <div class="text-right" v-if="item.cid == 5">{{ item.jine }}{{cnameList[4].cname}}</div>
          <div class="text-right" v-if="item.cid == 6">{{ item.jine }}{{cnameList[5].cname}}</div>
        </div>
      </van-list>
      <empty :text="$t('Base.empty2')" v-if="list.length == 0" />
    </div>

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="filterList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List, Popup, Picker } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      showPicker: ref(false),
      filterList: ref([]),
      shouyi: ref({}),
      cnameList: ref([
        {
          cid: 1,
          cname: "SAI",
        },
        {
          cid: 2,
          cname: "USDT",
        },
        {
          cid: 3,
          cname: "USDA",
        },
        {
          cid: 4,
          cname: "冻结USDA",
        },
        {
          cid: 5,
          cname: "冻结SAI",
        },
        {
          cid: 6,
          cname: "CCAI",
        },
      ])
    }
  },
  created() {
    //console.log(this.cnameList)
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
    this.getShouyi()
  },
  methods: {
    load(){
      /* this.loading = true
      this.pageIndex++ */
      this.list = []
      this.getdata()
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    back() {
      this.$router.back()
    },
    onConfirm(e) {
      if(e.val == 99) {
        this.list = this.allList
      } else {
        this.list = this.allList.filter(item => {
          return item.lx == e.val
        })
      }
      this.showPicker = false
    },
    getShouyi() {
      let _this = this
      _this.$request.post(
        "api/Users/GetShouyi",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          // console.log(data)
          _this.shouyi = data
        }
      )
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Bonus/SourceList",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          date: ""
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    }
  }
}
</script>

<style scoped>
.bill {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 10px;
}

.list-name {
  display: flex;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.list-val > div {
  flex: 1;
}

.cell {
  margin-top: 15px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.total {
  padding: 15px 15px 0;
}

.total-row {
  margin-top: 6px;
  display: flex;
}

.total-item {
  padding-bottom: 10px;
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(231, 228, 228, 0.7);
  border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
  border-right: 0;
}

.item-border {
  border-right: 1px solid rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
  font-weight: bold;
  font-size: 15px;
}
</style>